'use client';

import { FragmentType, gql } from '@/__generated__';
import { ImageAssetFragment } from '@/__generated__/graphql';
import { useReadingMode } from '@/components/AccessibilityActions/useAccessibilityActions';
import { withFragmentArray } from '@liquorice/gql-utils';
import { firstNonNullable, MaybeArrayOf } from '@liquorice/utils';
import Image, { ImageProps } from 'next/image';

export const IMAGE_ASSET_FRAGMENT = gql(`
  fragment ImageAsset on AssetInterface {
    id
    url
    width
    height
    alt
    filename
  }
`);

export type ImageAssetData = FragmentType<typeof IMAGE_ASSET_FRAGMENT>;
export type MaybeImage = MaybeArrayOf<ImageAssetData>;

export type ImageAssetOwnProps = Partial<ImageProps>;

export type ImageAssetProps = ImageAssetOwnProps & {
  data?: MaybeImage | ImageAssetFragment;
};

const ImageAsset = withFragmentArray(IMAGE_ASSET_FRAGMENT, (data, props: ImageAssetOwnProps) => {
  const imageAsset = firstNonNullable(data);
  if (!imageAsset || !imageAsset.url) return null;

  const { url, width, height, alt } = imageAsset;

  const { value: isReadingMode } = useReadingMode();

  if (isReadingMode) return <></>;

  return <Image src={url} width={width || 100} height={height || 100} alt={alt || ''} {...props} />;
}) as React.FC<ImageAssetProps>;

export default ImageAsset;
